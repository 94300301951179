import {
  CheckoutSdkHostEventMap,
  CheckoutSdkHostEventType,
  BaseSdkEventsDispatcher,
  CheckoutSdkFrameEventMap,
  CheckoutSdkProxyMessagesOf,
} from '@mangopay/checkout-sdk-hosted-core';

export class SdkEventsDispatcher extends BaseSdkEventsDispatcher<CheckoutSdkFrameEventMap, CheckoutSdkHostEventMap> {
  private sdkEventsDispatcherHandler:
    | ((message: CheckoutSdkProxyMessagesOf<CheckoutSdkHostEventMap>) => void)
    | undefined;

  dispatchToApp<T extends keyof CheckoutSdkFrameEventMap>(eventType: T, data: CheckoutSdkFrameEventMap[T]): void {
    this.dispatchMessage(eventType, data);
  }

  protected onIncomingMessage(message: CheckoutSdkProxyMessagesOf<CheckoutSdkHostEventMap>): void {
    if (
      this.sdkEventsDispatcherHandler &&
      Object.values(CheckoutSdkHostEventType).includes(message.eventType as CheckoutSdkHostEventType)
    ) {
      this.sdkEventsDispatcherHandler(message);
    }
  }

  public init(sdkEventsDispatcherHandler: (message: CheckoutSdkProxyMessagesOf<CheckoutSdkHostEventMap>) => void) {
    this.sdkEventsDispatcherHandler = sdkEventsDispatcherHandler;
  }
}

export const sdkEventsDispatcherInstance = new SdkEventsDispatcher();
