export interface TypedError {
  Status?: string;
  ResultCode?: string;
  ResultMessage?: string;
}

export class CustomTypedError extends Error {
  Status?: string;

  ResultCode?: string;

  ResultMessage?: string;

  constructor(error: TypedError) {
    super(JSON.stringify(error));
    this.Status = error.Status;
    this.ResultCode = error.ResultCode;
    this.ResultMessage = error.ResultMessage;
  }
}
