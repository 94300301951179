import { generateTokenizationSpecification } from './tokenization-specification';
import { CardPaymentMethod } from '../types';
import { BasePaymentMethod } from '../../../common/types';

export const generateCardPaymentMethod = (
  baseCardPaymentMethod: BasePaymentMethod,
  gatewayMerchantId?: string,
  gateway?: string
): CardPaymentMethod => ({
  tokenizationSpecification: generateTokenizationSpecification(gatewayMerchantId, gateway),
  ...baseCardPaymentMethod,
});
