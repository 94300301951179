import { DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { useGlobalContext } from '../globalContext';
import { useSentryDebugger } from '../sentryLogger';
import { useLoadNethoneProfilerScript } from './useLoadNethoneProfilerScript';

export const useNethoneProfiler = () => {
  const { loadScript } = useLoadNethoneProfilerScript();
  const { profilingAttemptReference } = useGlobalContext();
  const { addBreadcrumb } = useSentryDebugger();

  const initNethone = (profilingMerchantId: string) => {
    if (process.env.REACT_APP_WT_ENVIRONMENT !== 'local') {
      loadScript(profilingMerchantId, () => {
        const initOptions = {
          attemptReference: profilingAttemptReference,
          sensitiveFields: ['ccn', 'cvv'],
        };
        window.dftp.init(initOptions);
        addBreadcrumb(DebuggerLogType.NETHONE_PROFILER_INITIALIZED);
      });
    }
  };

  const checkIsCompleted = async () => {
    const isScriptLoaded = !!window.dftp;
    if (process.env.REACT_APP_WT_ENVIRONMENT === 'local') {
      await Promise.resolve();
      return;
    }
    if (isScriptLoaded) {
      await window.dftp.profileCompleted();
    } else {
      await Promise.reject();
    }
  };

  return {
    checkIsCompleted,
    initNethone,
  };
};
