export enum PaymentStatus {
  Succeeded = 'SUCCEEDED',
  Authorised = 'AUTHORISED',
  Validated = 'VALIDATED',
  Created = 'CREATED',
  Error = 'ERROR',
  Failed = 'FAILED',
}

export const paymentSuccessStatuses = [
  PaymentStatus.Succeeded,
  PaymentStatus.Authorised,
  PaymentStatus.Created,
  PaymentStatus.Validated,
];
