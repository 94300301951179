import { useEffect } from 'react';
import { CurrencyCode } from '@mangopay/checkout-sdk-elements-core';
import { useGlobalContext } from '../../globalContext';
import { SUPPORTED_CURRENCY_CODES } from '../constants';

export const useSupportedCurrencyCodes = (): void => {
  const { options } = useGlobalContext();
  const currencyCode = options?.amount?.currency as CurrencyCode;

  useEffect(() => {
    const isMatch = SUPPORTED_CURRENCY_CODES.includes(currencyCode);
    if (currencyCode && !isMatch) {
      throw new Error('Unsupported currency code');
    }
  }, [options?.amount?.currency]);
};

export default useSupportedCurrencyCodes;
