import { AuthorizePaymentInput } from '@mangopay/sdk-payment-methods';
import { CheckoutSdkFrameEventType } from '@mangopay/checkout-sdk-hosted-core';
import { CheckoutSdkFrameOptions } from '@mangopay/checkout-sdk-elements-core';
import { generateRedirectUrl } from './generate-redirect-url';
import { useGlobalContext } from '../../globalContext';
import { validateRequiredQueryParams } from '../../utils/get-query-params';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';

export const useApplePay = () => {
  const { options } = useGlobalContext();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { sourceOrigin, loaderVersion, sdkType } = validateRequiredQueryParams();
  const payWithApplePay = () => {
    const { amount } = options as CheckoutSdkFrameOptions;
    // generate redirectUrl
    const redirectUrl = generateRedirectUrl({
      sourceOrigin,
      loaderVersion,
      sdkType,
      origin: window.origin,
    });
    const paymentData: AuthorizePaymentInput = {
      currencyCode: amount.currency,
      amount: amount.value ?? 0,
      perform3DSecure: {
        redirectUrl,
      },
    };
    dispatchMessageToApp(CheckoutSdkFrameEventType.CreateApplePayPaymentRequest, paymentData);
  };

  return {
    payWithApplePay,
  };
};
