/* https://developers.google.com/pay/api/web/guides/tutorial */
import { TokenizationSpecification } from '../types';

export const generateTokenizationSpecification = (
  merchantId?: string,
  gateway?: string
): TokenizationSpecification => ({
  type: 'PAYMENT_GATEWAY',
  parameters: {
    gateway: gateway ?? '',
    gatewayMerchantId: merchantId ?? '',
  },
});
