import { CheckoutSdkEnvironment, CheckoutSdkOptions } from '@mangopay/checkout-sdk-elements-core';
import { httpClientInstance } from '@mangopay/sdk-api-core';
import { CheckoutSdkFrameEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { useSentryDebugger } from '../sentryLogger';
import { findMissingOptions } from '../utils/find-missing-options';
import { useSupportedCurrencyCodes, useSupportedPaymentMethods } from '../common';
import { useGlobalContext } from '../globalContext';
import { useNethoneProfiler } from '../nethone';
import { useLoadGoogleScript } from '../payment-methods';
import { useSdkEventsDispatcher } from '../sdk-events-dispatcher';

export const useInitSdk = () => {
  const { initSentry, addBreadcrumb, logError } = useSentryDebugger();
  const { setOptions, profilingAttemptReference } = useGlobalContext();
  const { initNethone } = useNethoneProfiler();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  useSupportedPaymentMethods();
  useSupportedCurrencyCodes();
  useLoadGoogleScript();

  const handleFindMissingOptions = (sdkOptions?: CheckoutSdkOptions) => {
    const { missingOptions, hasMissingOptions } = findMissingOptions(sdkOptions);
    if (hasMissingOptions) {
      addBreadcrumb(DebuggerLogType.MISSING_OPTIONS_ERROR, { missingOptions }, 'error');
      const errorMessage = `One or several required parameters are missing or incorrect: ${missingOptions?.join(', ')}`;
      logError(new Error(errorMessage));
      throw new Error(errorMessage);
    }
    return {
      missingOptions,
      hasMissingOptions,
    };
  };
  const startSizeObserver = () => {
    const element = document.querySelector('#root');

    if (element) {
      const resizeObserver = new ResizeObserver(([container]) => {
        dispatchMessageToApp(CheckoutSdkFrameEventType.Resize, container.contentRect);
      });

      resizeObserver.observe(element);
    }
  };

  const handleInitialize = (updatedOptions: CheckoutSdkOptions) => {
    const sdkOptions: CheckoutSdkOptions = {
      ...updatedOptions,
      environment: updatedOptions.environment || CheckoutSdkEnvironment.SANDBOX,
    };
    handleFindMissingOptions(sdkOptions);
    httpClientInstance.init(sdkOptions.tenantId && { tenantId: sdkOptions.tenantId });
    setOptions(sdkOptions);
    initNethone(sdkOptions.profilingMerchantId);
    initSentry(sdkOptions, profilingAttemptReference);
    addBreadcrumb(DebuggerLogType.SDK_INITIALIZED, sdkOptions);
    startSizeObserver();
    dispatchMessageToApp(CheckoutSdkFrameEventType.Initialize, undefined);
  };

  return { handleInitialize };
};
