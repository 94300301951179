import { CheckoutSdkFrameEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { TypedError, createScriptElement, CreateScriptElement } from '@mangopay/checkout-sdk-core';
import { NETHONE_PROFILER_SCRIPT_ID, LOAD_NETHONE_PROFILER_SCRIPT_ERROR_MESSAGE } from './constant';
import { getNethoneProfilerUrl } from './get-nethone-profiler-url';
import { useSentryDebugger } from '../sentryLogger';
import { useSdkEventsDispatcher } from '../sdk-events-dispatcher';

export const useLoadNethoneProfilerScript = () => {
  const { addBreadcrumb, logError } = useSentryDebugger();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const loadScript = (profilingMerchantId: string, onLoad: () => void): CreateScriptElement => {
    const onScriptLoad = () => {
      onLoad();
    };

    const onScriptError = () => {
      const error: TypedError = {
        Status: 'ERROR',
        ResultMessage: LOAD_NETHONE_PROFILER_SCRIPT_ERROR_MESSAGE,
      };
      addBreadcrumb(DebuggerLogType.NETHONE_PROFILER_SCRIPT_LOAD_FAILED, undefined, 'error');
      logError(new Error(error.ResultMessage as string));
      dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
    };

    return createScriptElement({
      src: getNethoneProfilerUrl(profilingMerchantId),
      id: NETHONE_PROFILER_SCRIPT_ID,
      onScriptLoad,
      onScriptError,
      crossOrigin: 'use-credentials',
    });
  };

  return { loadScript };
};
