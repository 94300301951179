/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  GooglePayCardAuthMethod,
  GooglePayPaymentMethodOptions,
  GooglePayMerchantInfo,
  GooglePayTransactionInfo,
  GooglePayButtonConfig,
  GooglePayButtonColor,
  GooglePayButtonType,
  GooglePayCardParameters,
  GooglePayPaymentData,
  GooglePayEnvironment,
} from '@mangopay/checkout-sdk-elements-core';
import { getGooglePayEnvironment } from './get-googlepay-environment';

const defaultAllowedCardNetworks = ['VISA', 'MASTERCARD'];

const defaultAllowedAuthMethods = [GooglePayCardAuthMethod.PAN_ONLY, GooglePayCardAuthMethod.CRYPTOGRAM_3DS];

const defaultCardParameters = {
  allowedAuthMethods: defaultAllowedAuthMethods,
  allowedCardNetworks: defaultAllowedCardNetworks,
  allowPrepaidCards: true,
  allowCreditCards: true,
  billingAddressRequired: false,
  billingAddressParameters: undefined,
};

const defaultPaymentData = {
  emailRequired: false,
  shippingAddressRequired: false,
  shippingOptionRequired: false,
  shippingAddressParameters: undefined,
  shippingOptionParameters: undefined,
};

const defaultButtonConfig = {
  buttonSizeMode: 'fill',
  buttonColor: 'black' as GooglePayButtonColor,
  buttonType: 'buy' as GooglePayButtonType,
  buttonLocale: undefined,
};

export interface DefaultGooglePayButtonConfig extends GooglePayButtonConfig {
  buttonSizeMode: string;
}

export interface DefaultConfigOption
  extends Omit<GooglePayPaymentMethodOptions, 'merchantInfo' | 'transactionInfo' | 'gatewayMerchantId'> {
  environment: GooglePayEnvironment;
  merchantInfo?: GooglePayMerchantInfo;
  gateway?: string;
  transactionInfo?: GooglePayTransactionInfo;
  button: DefaultGooglePayButtonConfig;
  gatewayMerchantId?: string;
}

export const defaultGooglePayConfig: DefaultConfigOption = {
  environment: getGooglePayEnvironment(),
  merchantInfo: undefined,
  gatewayMerchantId: undefined,
  gateway: 'whenthen',
  transactionInfo: undefined,
  cardParameters: defaultCardParameters,
  existingPaymentMethodRequired: true,
  paymentData: defaultPaymentData,
  button: defaultButtonConfig,
};

export const mergeGooglePayConfig = (
  defaultConfig: DefaultConfigOption,
  config: GooglePayPaymentMethodOptions,
  environment: GooglePayEnvironment
) => ({
  environment: environment || config?.environment || defaultConfig.environment,
  merchantInfo: {
    ...defaultConfig.merchantInfo,
    ...(config?.merchantInfo || {}),
  },
  gatewayMerchantId: config?.gatewayMerchantId || defaultConfig.gatewayMerchantId,
  gateway: config?.gateway || defaultConfig.gateway,
  transactionInfo: {
    ...defaultConfig.transactionInfo,
    ...(config?.transactionInfo || {}),
  },
  cardParameters: {
    ...defaultConfig.cardParameters,
    ...(config?.cardParameters || {}),
  } as GooglePayCardParameters,
  existingPaymentMethodRequired: config?.existingPaymentMethodRequired || defaultConfig.existingPaymentMethodRequired,
  paymentData: {
    ...defaultConfig.paymentData,
    ...(config?.paymentData || {}),
  } as GooglePayPaymentData,
  button: {
    ...defaultConfig.button,
    ...(config?.button || {}),
  } as GooglePayButtonConfig,
});
