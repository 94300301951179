export interface ExtensionErrorOutput {
  url?: string;
  code?: ExtensionErrorCode;
  supportsIframe?: boolean;
  paymentMethod?: string;
}

export enum AuthorizeErrorCode {
  Apm = 'error.authorize.requiresApmAuthorization',
  ThreeDs = 'error.authorize.requires3DSecure',
}

export type ExtensionErrorCode = AuthorizeErrorCode.Apm | AuthorizeErrorCode.ThreeDs | string;
