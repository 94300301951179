import { CheckoutSdkFrameParam } from '@mangopay/checkout-sdk-hosted-core';
import { AuthorizeRedirectUrlParamKeys, GenerateAuthorizeMgpFrameRedirectUrl } from '../../common/types';

export const generateRedirectUrl = ({
  sourceOrigin,
  loaderVersion,
  origin,
  sdkType,
}: GenerateAuthorizeMgpFrameRedirectUrl): string => {
  if (!sourceOrigin || !loaderVersion) throw new Error('Required param is missing.');

  const url = new URL(origin);
  url.searchParams.set(CheckoutSdkFrameParam.Referrer, sourceOrigin);
  url.searchParams.set(CheckoutSdkFrameParam.Version, loaderVersion);
  url.searchParams.set(CheckoutSdkFrameParam.SdkType, sdkType);
  url.searchParams.set(AuthorizeRedirectUrlParamKeys.MangopayFrameUrlKey, origin);
  url.searchParams.set(AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey, 'true');

  return url.toString();
};
