import { TypedError } from '@mangopay/checkout-sdk-core';
import { GooglePayPaymentResult } from '@mangopay/checkout-sdk-elements-core';
import {
  CheckoutSdkFrameEventType,
  CheckoutSdkHostEventType,
  DebuggerLogType,
} from '@mangopay/checkout-sdk-hosted-core';
import { PaymentDataRequest, PaymentsClientResult } from './types';
import { useGlobalContext } from '../../globalContext';
import { useSentryDebugger } from '../../sentryLogger';
import { PaymentStatus } from '../../common';
import { usePaymentResultState } from '../usePaymentResultState';
import { sdkEventsDispatcherInstance, useSdkEventsDispatcher } from '../../sdk-events-dispatcher';
import { useHandlePayInComplete } from '../useHandlePayInComplete';
import { CreateGooglePayPayInCompleteEvent } from './types/create-google-pay-pay-in-complete-event';

export interface HandleGooglePayParams {
  paymentsClient: PaymentsClientResult;
  paymentDataRequest: PaymentDataRequest;
  transactionId: string;
}

export interface LoadPaymentDataError {
  statusMessage: string;
}

export const useGooglePayHandlers = () => {
  const { profilingAttemptReference, setIsLoading, options } = useGlobalContext();
  const { addBreadcrumb, logError, logEvent } = useSentryDebugger();
  const { handleErrorState } = usePaymentResultState();
  const { handleCreatePayInPayment } = useHandlePayInComplete();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();

  const handleDispatchUnknownError = (message?: string): void => {
    const error: TypedError = {
      Status: 'ERROR',
      ResultCode: '205001',
      ResultMessage: message || 'Unknown error',
    };
    addBreadcrumb(DebuggerLogType.PAYMENT_ERRORED, { paymentMethod: 'google_pay' }, 'error');
    logError(new Error(error.ResultMessage));
    dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
  };

  const googlePayTokenizationComplete = (result: GooglePayPaymentResult) => {
    const isError = !result?.paymentMethodData?.tokenizationData?.token;
    if (isError) {
      handleErrorState({
        declineMessage: 'No wallet token was returned from Google Pay',
        status: PaymentStatus.Error,
        paymentMethod: 'google_pay',
      });
    } else {
      addBreadcrumb(DebuggerLogType.TOKENIZATION_COMPLETED, { paymentMethod: 'google_pay' });
      // notify main app - TokenizationComplete event
      dispatchMessageToApp(CheckoutSdkFrameEventType.TokenizationComplete, {
        ...result,
        profilingAttemptReference,
      });
      logEvent({ message: 'Tokenization completed' });
    }
    return { isError };
  };

  const handleCreateGooglePayPaymentResult = (event: MessageEvent<CreateGooglePayPayInCompleteEvent>) => {
    if (event.data.eventType === CheckoutSdkHostEventType.CreateGooglePayPaymentComplete) {
      handleCreatePayInPayment(event.data.data);
      setIsLoading(false);
      window.removeEventListener('message', handleCreateGooglePayPaymentResult);
    }
  };

  // triggers the dialog when all data are computed and handles the token based on  sdk types
  const payWithGooglePay = async ({ paymentsClient, paymentDataRequest }: HandleGooglePayParams): Promise<void> => {
    try {
      const paymentResult: GooglePayPaymentResult = await paymentsClient?.loadPaymentData(paymentDataRequest);
      const { isError } = googlePayTokenizationComplete(paymentResult);
      if (isError) {
        return;
      }
      if (options?.handleGooglePayPayment) {
        setIsLoading(true);
        sdkEventsDispatcherInstance.dispatchToApp(CheckoutSdkFrameEventType.CreateGooglePayPayment, {
          PaymentData: paymentResult.paymentMethodData.tokenizationData.token,
          ProfilingAttemptReference: profilingAttemptReference,
        });
        window.addEventListener('message', handleCreateGooglePayPaymentResult);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      handleDispatchUnknownError((error as LoadPaymentDataError)?.statusMessage);
    }
  };

  return {
    payWithGooglePay,
    handleDispatchUnknownError,
    googlePayTokenizationComplete,
  };
};
