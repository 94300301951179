import * as Sentry from '@sentry/browser';
import type { Event, SeverityLevel, BreadcrumbHint } from '@sentry/types';
import { CheckoutSdkFrameParam, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { CheckoutSdkOptions } from '@mangopay/checkout-sdk-elements-core';

export enum SentryTagName {
  CLIENT_ID = 'clientId',
  PROFILING_MERCHANT_ID = 'profilingMerchantId',
  CHECKOUT_REFERENCE = 'checkoutReference',
  VERSION = 'version',
  REFERRER = 'referrer',
  PAYMENT_METHOD = 'paymentMethod',
}

// type LogMetadata = Record<string, unknown>
interface LogData {
  type: DebuggerLogType | string;
  data?: object;
}

export type LogCategory = 'default' | 'debug' | 'error' | 'navigation';

export interface Log {
  category?: LogCategory;
  data?: LogData;
  type?: string;
}

export const useSentryDebugger = () => {
  const params = new URLSearchParams(window.location.search);
  const referrer = params.get(CheckoutSdkFrameParam.Referrer);
  const sdkVersion = params.get(CheckoutSdkFrameParam.Version) ?? '0.0.0';

  const initSentry = (options: CheckoutSdkOptions, checkoutReference: string) => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      environment: options.environment?.toLowerCase(),
      // Turn off general error handling
      defaultIntegrations: false,
      integrations: [
        Sentry.breadcrumbsIntegration(),
        // Sentry.browserTracingIntegration(),
        // Sentry.replayIntegration(),
      ],
      attachStacktrace: true,
      normalizeDepth: 5,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
    Sentry.setTag(SentryTagName.CLIENT_ID, options?.clientId);
    Sentry.setTag(SentryTagName.PROFILING_MERCHANT_ID, options?.profilingMerchantId);
    Sentry.setTag(SentryTagName.CHECKOUT_REFERENCE, checkoutReference);
    Sentry.setTag(SentryTagName.VERSION, sdkVersion);
    Sentry.setTag(SentryTagName.REFERRER, referrer);
  };

  const addBreadcrumb = (type: DebuggerLogType, data?: object, level?: SeverityLevel, hint?: BreadcrumbHint) => {
    Sentry.addBreadcrumb(
      {
        data: {
          type,
          data,
        },
        level: level || 'log',
        category: 'default',
      },
      hint
    );
  };

  const logError = (exception: Error) => {
    Sentry.captureException(exception);
  };

  const logEvent = (event: Event) => {
    Sentry.captureEvent({
      ...event,
      level: event.level || 'log',
    });
  };

  const setTag = (tag: SentryTagName, value: string) => {
    Sentry.setTag(tag, value);
  };

  return {
    initSentry,
    addBreadcrumb,
    logError,
    logEvent,
    setTag,
  };
};
