import { CheckoutSdkFrameEventType, CheckoutSdkProxyMessage } from '@mangopay/checkout-sdk-hosted-core';
import { AuthorizePostMessagePayload, isPopUpWindow } from '@mangopay/checkout-sdk-core';
import { getRedirectPayload, getRedirectUrlParams } from './utils';

const HandleRedirectComponent = () => {
  const urlParams = getRedirectUrlParams();
  const payload = getRedirectPayload(urlParams);
  if (isPopUpWindow()) {
    const message: CheckoutSdkProxyMessage<
      CheckoutSdkFrameEventType.CloseHostAuthPopupWindow,
      AuthorizePostMessagePayload
    > = {
      eventType: CheckoutSdkFrameEventType.CloseHostAuthPopupWindow,
      data: payload,
    };
    const openerWindow: Window = window.opener as Window;
    openerWindow.postMessage(message, '*');
  } else {
    const message: CheckoutSdkProxyMessage<
      CheckoutSdkFrameEventType.CloseHostAuthPopupModal,
      AuthorizePostMessagePayload
    > = {
      eventType: CheckoutSdkFrameEventType.CloseHostAuthPopupModal,
      data: payload,
    };
    window.parent.postMessage(message, '*');
  }
  return null;
};
export default HandleRedirectComponent;
