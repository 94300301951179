import { ErrorOutput } from './error-output';
import { AuthorizeErrorCode } from './error-extensions';

export interface CheckHasAuthorizeErrorToHandleResult {
  hasAuthorizeErrorToHandle: boolean;
  authUrl?: string;
  supportsIframe: boolean;
}

const handledAuthorizeErrorCodes = [AuthorizeErrorCode.Apm, AuthorizeErrorCode.ThreeDs];

export const checkHasAuthorizeErrorToHandle = (errors?: ErrorOutput[] | null): CheckHasAuthorizeErrorToHandleResult => {
  const errorObject = errors?.find(
    (error) =>
      error.extensions?.code && handledAuthorizeErrorCodes.includes(error.extensions?.code as AuthorizeErrorCode)
  );

  return {
    hasAuthorizeErrorToHandle: Boolean(errorObject),
    authUrl: errorObject?.extensions?.url,
    supportsIframe: Boolean(errorObject?.extensions?.supportsIframe),
  };
};
