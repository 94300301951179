export enum CurrencyCode {
  AED = 'AED',
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  JPY = 'JPY',
  NOK = 'NOK',
  PLN = 'PLN',
  SEK = 'SEK',
  USD = 'USD',
}
