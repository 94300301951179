import { useEffect } from 'react';
import { PaymentMethod } from '@mangopay/sdk-payment-methods';
import { useGlobalContext } from '../../globalContext';
import { SUPPORTED_PAYMENT_METHODS } from '../constants';

export const useSupportedPaymentMethods = (): void => {
  const { options } = useGlobalContext();

  useEffect(() => {
    const apms = options?.paymentMethods?.map((apm) => apm.type?.toUpperCase() as PaymentMethod);
    const isMatch = apms?.every((apm) => SUPPORTED_PAYMENT_METHODS?.includes(apm));
    if (apms?.length && !isMatch) {
      throw new Error('Unsupported payment methods');
    }
  }, [options?.paymentMethods]);
};

export default useSupportedPaymentMethods;
