import { CheckoutSdkFrameEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { PaymentMethodType } from '@mangopay/checkout-sdk-elements-core';
import { Nullable } from '@mangopay/checkout-sdk-core';
import { useGlobalContext } from '../globalContext';
import { useSentryDebugger } from '../sentryLogger';
import { useSdkEventsDispatcher } from '../sdk-events-dispatcher';

export interface HandleErrorStateParams {
  declineMessage: string;
  status?: string;
  declineCode?: Nullable<string>;
  paymentMethod?: PaymentMethodType;
  errors?: Record<string, string>;
}

export const usePaymentResultState = () => {
  const { setShowSuccessState, handlePaymentFlow } = useGlobalContext();
  const { addBreadcrumb, logError } = useSentryDebugger();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();

  const handleSuccessState = () => {
    if (handlePaymentFlow) {
      setShowSuccessState(true);
    }
  };

  const handleErrorState = ({ declineMessage, status, declineCode, paymentMethod, errors }: HandleErrorStateParams) => {
    const error = {
      ...(status && { Status: status }),
      ...(declineCode && { ResultCode: declineCode }),
      ...(declineMessage && { ResultMessage: declineMessage }),
      ...(errors && { Errors: errors }),
    };
    addBreadcrumb(DebuggerLogType.PAYMENT_ERRORED, paymentMethod && { paymentMethod }, 'error');
    logError(new Error(error.ResultMessage));
    dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
  };

  return {
    handleSuccessState,
    handleErrorState,
  };
};
