import { baseRequest } from './base-request';
import { GeneratePaymentDataRequestParams, PaymentDataRequest } from '../types';

export const generatePaymentDataRequest = ({
  cardPaymentMethod,
  transactionInfo,
  merchantInfo,
  paymentData,
  sourceOrigin,
}: GeneratePaymentDataRequestParams): PaymentDataRequest => ({
  ...baseRequest,
  allowedPaymentMethods: [cardPaymentMethod],
  transactionInfo,
  merchantInfo: {
    merchantName: merchantInfo.merchantName,
    merchantId: merchantInfo.merchantId,
    merchantOrigin: sourceOrigin,
  },
  shippingAddressRequired: paymentData?.shippingAddressRequired,
  emailRequired: paymentData?.emailRequired,
  shippingOptionRequired: paymentData?.shippingOptionRequired,
  shippingAddressParameters: paymentData?.shippingAddressParameters,
  shippingOptionParameters: paymentData?.shippingOptionParameters,
});
