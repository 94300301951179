import { CreateCardDirectPayInResult } from '@mangopay/sdk-payment-methods';

export interface ExecutionDetails {
  SecureModeNeeded?: boolean;
  SecureModeRedirectURL?: string;
}

export interface ResultWithExecutionDetails extends CreateCardDirectPayInResult {
  ExecutionDetails?: ExecutionDetails;
}

const extractExecutionDetails = (data: ResultWithExecutionDetails): ExecutionDetails | undefined =>
  data.ExecutionDetails as ExecutionDetails;

export const getIsSecureModeValid = (data: ResultWithExecutionDetails): boolean => {
  const executionDetails = extractExecutionDetails(data);
  return !(
    typeof data.SecureModeRedirectURL === 'undefined' && typeof executionDetails?.SecureModeRedirectURL === 'undefined'
  );
};

export const getSecureModeRedirectURL = (data: ResultWithExecutionDetails): string | undefined => {
  const executionDetails = extractExecutionDetails(data);
  return data.SecureModeRedirectURL || executionDetails?.SecureModeRedirectURL;
};
