import { useEffect, useCallback } from 'react';
import { GooglePayPaymentMethodOptions } from '@mangopay/checkout-sdk-elements-core';
import { createScriptElement, TypedError } from '@mangopay/checkout-sdk-core';
import { CheckoutSdkFrameEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { checkIsGooglePayLoaded, getGooglePayApm } from './utils';
import { GOOGLE_PLAY_SCRIPT_SRC, LOAD_GOOGLE_SCRIPT_ERROR_MESSAGE } from './constants';
import { useGlobalContext } from '../../globalContext';
import { useSentryDebugger } from '../../sentryLogger';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';

export const useLoadGoogleScript = (): void => {
  const { options } = useGlobalContext();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { addBreadcrumb, logError } = useSentryDebugger();
  const googlePayOption = getGooglePayApm(options?.paymentMethods)?.options as GooglePayPaymentMethodOptions;

  const onScriptError = useCallback((): void => {
    const error: TypedError = {
      Status: 'ERROR',
      ResultMessage: LOAD_GOOGLE_SCRIPT_ERROR_MESSAGE,
    };
    addBreadcrumb(DebuggerLogType.GOOGLE_PAY_SCRIPT_LOAD_FAILED, undefined, 'error');
    logError(new Error(error.ResultMessage));
    dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
  }, []);

  useEffect(() => {
    const isGooglePayLoaded = checkIsGooglePayLoaded();

    if (!googlePayOption || isGooglePayLoaded) return undefined;
    const { removeElementFn } = createScriptElement({ src: GOOGLE_PLAY_SCRIPT_SRC, onScriptError });

    addBreadcrumb(DebuggerLogType.GOOGLE_PAY_INITIALIZED);

    return () => {
      removeElementFn();
    };
  }, [googlePayOption]);
};

export default useLoadGoogleScript;
