interface CreateHtmlElementParams {
  src: string;
  id?: string;
  onScriptLoad?: (this: HTMLScriptElement, ev: Event) => void;
  onScriptError?: (this: HTMLScriptElement, ev: Event) => void;
  crossOrigin?: 'anonymous' | 'use-credentials';
}

export interface CreateScriptElement {
  element: HTMLScriptElement;
  removeElementFn: () => void;
}

export const createScriptElement = ({
  src,
  id,
  onScriptLoad,
  onScriptError,
  crossOrigin,
}: CreateHtmlElementParams): CreateScriptElement => {
  const script: HTMLScriptElement = document.createElement('script');
  script.src = src;
  if (id) {
    script.id = id;
  }
  if (crossOrigin) {
    script.crossOrigin = crossOrigin;
  }
  script.async = true;
  script.defer = true;
  if (onScriptLoad) {
    script.addEventListener('load', onScriptLoad);
  }
  if (onScriptError) {
    script.addEventListener('error', onScriptError);
  }
  document.body.appendChild(script);

  const removeElementFn = () => {
    if (onScriptLoad) {
      script.removeEventListener('load', onScriptLoad);
    }
    if (onScriptError) {
      script.removeEventListener('error', onScriptError);
    }
    document.body.removeChild(script);
  };
  return {
    element: script,
    removeElementFn,
  };
};
