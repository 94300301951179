import { CheckoutSdkFrameParam } from '@mangopay/checkout-sdk-hosted-core';
import { CheckoutSdkType } from '@mangopay/checkout-sdk-elements-core';
import { CLIENT_ID, CLIENT_TOKEN, TRANSACTION_ID_KEY } from '../redirect/redirect-param-keys';

export const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const sourceOrigin = params.get(CheckoutSdkFrameParam.Referrer);
  const loaderVersion = params.get(CheckoutSdkFrameParam.Version) ?? '0.0.0';
  const sdkType = params.get(CheckoutSdkFrameParam.SdkType) as CheckoutSdkType;
  const transactionId = params.get(TRANSACTION_ID_KEY);
  const clientToken = params.get(CLIENT_TOKEN);
  const clientId = params.get(CLIENT_ID);

  return { sourceOrigin, loaderVersion, host: window, sdkType, clientToken, clientId, transactionId };
};

export const validateRequiredQueryParams = () => {
  const { sourceOrigin, sdkType } = getQueryParams();
  if (!sourceOrigin) {
    throw new Error(`SourceOrigin is required.`);
  }

  if (!sdkType) {
    throw new Error(`SDK Type is required.`);
  }
  return { ...getQueryParams(), sourceOrigin, sdkType };
};
