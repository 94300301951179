import { PreregistrationData, CardInfoObject, MangopayVault, TokenizePaymentMethodResult } from '@mangopay/vault-sdk';
import { CheckoutSdkEnvironment } from '@mangopay/checkout-sdk-elements-core';
import { TenantId } from '@mangopay/sdk-api-core';

export const tokenizePaymentMethod = async (
  clientId: string,
  preregistrationData: PreregistrationData,
  cardInfoObject: CardInfoObject,
  environment?: CheckoutSdkEnvironment,
  tenantId?: TenantId
): Promise<TokenizePaymentMethodResult> => {
  const vault = MangopayVault.initialize({ clientId, environment, tenantId });

  let response: TokenizePaymentMethodResult;

  try {
    response = await vault.tokenizePaymentMethod(cardInfoObject, preregistrationData);
  } catch (error) {
    return error as TokenizePaymentMethodResult;
  }

  return response;
};
