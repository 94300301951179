import { CheckoutSdkType } from '@mangopay/checkout-sdk-elements-core';

export enum AuthorizeRedirectUrlParamKeys {
  MangopayFrameUrlKey = 'mgp-frame-url',
  RedirectedFromAuthKey = 'mgp-redirected-from-auth',
}

export interface GenerateAuthorizeMgpFrameRedirectUrl {
  sourceOrigin: string;
  loaderVersion: string;
  origin: string;
  sdkType: CheckoutSdkType;
}
