import { GooglePayEnvironment } from '@mangopay/checkout-sdk-elements-core';

const WT_ENVIRONMENT = process.env.REACT_APP_WT_ENVIRONMENT;

export const getGooglePayEnvironment = (): GooglePayEnvironment => {
  switch (WT_ENVIRONMENT) {
    case 'production':
      return 'PRODUCTION';
    case 'sandbox':
      return 'PRODUCTION';
    case 'dev':
      return 'TEST';
    case 'local':
      return 'TEST';
    default:
      return 'PRODUCTION';
  }
};
