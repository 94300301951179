import { CheckoutSdkFrameEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { TokenizePaymentMethodResult } from '@mangopay/vault-sdk';
import { CreateCardRegistrationResult } from '@mangopay/checkout-sdk-elements-core';
import { CreateCardDirectPayInResult } from '@mangopay/sdk-payment-methods';
import { TypedError } from '@mangopay/checkout-sdk-core';
import { useGlobalContext } from '../../globalContext';
import { PaymentStatus } from '../../common';
import { useSentryDebugger } from '../../sentryLogger';
import { usePaymentResultState } from '../usePaymentResultState';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';

export type PaymentResult = CreateCardDirectPayInResult | TokenizePaymentMethodResult | CreateCardRegistrationResult;

/* Hook to show success state or failed state after payment is complete */
export const useCardPaymentComplete = () => {
  const { profilingAttemptReference: ProfilingAttemptReference } = useGlobalContext();
  const { addBreadcrumb, logEvent } = useSentryDebugger();
  const { handleErrorState, handleSuccessState } = usePaymentResultState();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();

  const isPaymentError = (result: PaymentResult): boolean =>
    !!(
      result.errors ||
      result.Status === PaymentStatus.Error ||
      result.Type?.includes('invalid') ||
      result.Type?.includes('forbidden')
    );

  const isPaymentSucceeded = (result: PaymentResult) =>
    result.Status === PaymentStatus.Succeeded || result.Status === PaymentStatus.Validated;
  const isPaymentFailed = (result: PaymentResult) => result.Status === PaymentStatus.Failed;

  const handleResult = (result: PaymentResult) => {
    const succeeded = isPaymentSucceeded(result);
    const failed = isPaymentFailed(result);
    const isError = isPaymentError(result);
    const errorMessage = (isError && result.Message) || result.ResultMessage;
    const declineCode = isError && result.ResultCode && `error.${result.ResultCode}`;
    const declineMessage = errorMessage || declineCode || 'error.unknown';
    if (succeeded) {
      handleSuccessState();
    }
    if (isError) {
      handleErrorState({
        declineMessage,
        status: result?.Status,
        declineCode: result?.ResultCode,
        paymentMethod: 'card',
      });
    }
    return { succeeded, failed, isError };
  };

  const paymentComplete = (result: CreateCardDirectPayInResult | TypedError): void => {
    const { succeeded, failed } = handleResult(result);
    if (succeeded || failed) {
      addBreadcrumb(succeeded ? DebuggerLogType.PAYMENT_COMPLETED : DebuggerLogType.PAYMENT_FAILED, {
        paymentMethod: 'card',
        result,
      });
      logEvent({ message: succeeded ? 'Payment completed' : 'Payment failed' });
      // notify main app - PaymentComplete event
      dispatchMessageToApp(CheckoutSdkFrameEventType.PaymentComplete, {
        ...result,
        ...(ProfilingAttemptReference && { ProfilingAttemptReference }),
      });
    }
  };

  const tokenizationComplete = (result: TokenizePaymentMethodResult | CreateCardRegistrationResult): void => {
    const { succeeded, failed } = handleResult(result);
    if (succeeded || failed) {
      addBreadcrumb(succeeded ? DebuggerLogType.TOKENIZATION_COMPLETED : DebuggerLogType.TOKENIZATION_FAILED, {
        paymentMethod: 'card',
        result,
      });
      logEvent({ message: succeeded ? 'Tokenization completed' : 'Tokenization failed' });
      // notify main app - TokenizationComplete event
      dispatchMessageToApp(CheckoutSdkFrameEventType.TokenizationComplete, {
        ...result,
        ...(ProfilingAttemptReference && { ProfilingAttemptReference }),
      });
    }
  };

  return {
    paymentComplete,
    tokenizationComplete,
    isPaymentError,
    isPaymentSucceeded,
    isPaymentFailed,
  };
};
