import { CurrencyCode } from '@mangopay/checkout-sdk-elements-core';

export const SUPPORTED_CURRENCY_CODES = [
  CurrencyCode.AED,
  CurrencyCode.AUD,
  CurrencyCode.CAD,
  CurrencyCode.CHF,
  CurrencyCode.DKK,
  CurrencyCode.EUR,
  CurrencyCode.GBP,
  CurrencyCode.HKD,
  CurrencyCode.JPY,
  CurrencyCode.NOK,
  CurrencyCode.PLN,
  CurrencyCode.SEK,
  CurrencyCode.USD,
];

export default SUPPORTED_CURRENCY_CODES;
