import { CardBrand } from './card-brand';

export const getCardBrand = (cardNumber: string): CardBrand => {
  if (RegExp('^4,*').test(cardNumber)) {
    return CardBrand.Visa;
  }

  if (RegExp('^5(1|2|3|4|5),*').test(cardNumber) || RegExp('^2,*').test(cardNumber)) {
    return CardBrand.Master;
  }

  if (RegExp('^3(4|7),*').test(cardNumber)) {
    return CardBrand.Amex;
  }

  if (RegExp('^5(0|6|7|8),*').test(cardNumber) || RegExp('^6,*').test(cardNumber)) {
    return CardBrand.Maestro;
  }

  return CardBrand.Other;
};
