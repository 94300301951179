import { GoogleWindow } from '../types';

export const checkIsGooglePayLoaded = (): boolean => {
  try {
    return (
      'google' in (window || global) &&
      !!(
        (window as unknown as GoogleWindow)?.google?.payments?.api?.PaymentsClient ||
        (global as unknown as GoogleWindow)?.google?.payments?.api?.PaymentsClient
      )
    );
  } catch {
    return false;
  }
};
