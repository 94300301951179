import { CreateGooglePayPayInResult } from '@mangopay/sdk-payment-methods';
import { ProfilingAttemptReference } from '@mangopay/checkout-sdk-core';

export interface CustomGooglePayPaymentRequestPayloadShippingAddress {
  [key: string]: string;
}

export interface GooglePayPaymentRequestPayloadShippingAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  administrativeArea: string;
  countryCode: string;
  locality: string;
  name: string;
  postalCode: string;
  sortingCode: string;
}

export interface GooglePayPaymentResult extends ProfilingAttemptReference {
  paymentMethodData: GooglePayPaymentMethodData;
  email?: string;
  shippingAddress?: GooglePayPaymentRequestPayloadShippingAddress | CustomGooglePayPaymentRequestPayloadShippingAddress;
}

export interface GooglePayPaymentMethodData {
  description: string;
  tokenizationData: GooglePayPaymentMethodDataTokenizationData;
  type: string;
  info: GooglePayPaymentMethodDataInfo;
}

export interface GooglePayPaymentMethodDataTokenizationData {
  type: string;
  token: string;
}

export interface GooglePayPaymentMethodDataInfo {
  cardNetwork: string;
  cardDetails: string;
  billingAddress?: GooglePayPaymentMethodDataInfoBillingAddress;
}

export interface GooglePayPaymentMethodDataInfoBillingAddress {
  name: string;
  countryCode: string;
  postalCode: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  administrativeArea?: string;
  locality?: string;
  sortingCode?: string;
  phoneNumber?: string;
}

export interface CreateGooglePayPaymentData {
  PaymentData: string;
  ProfilingAttemptReference: string;
}
export type CreateGooglePayPayment =
  | ((data?: CreateGooglePayPaymentData) => Promise<CreateGooglePayPayInResult>)
  | undefined;

export interface GooglePayPaymentMethodOptions {
  merchantInfo: GooglePayMerchantInfo;
  transactionInfo: GooglePayTransactionInfo;
  gatewayMerchantId?: string;
  gateway?: string;
  environment?: GooglePayEnvironment;
  cardParameters?: GooglePayCardParameters;
  existingPaymentMethodRequired?: boolean;
  paymentData?: GooglePayPaymentData;
  button?: GooglePayButtonConfig;
  onCreatePayment?: CreateGooglePayPayment;
}

export interface GooglePayCardParameters {
  allowedAuthMethods?: string[];
  allowedCardNetworks?: string[];
  allowPrepaidCards?: boolean;
  allowCreditCards?: boolean;
  billingAddressRequired?: boolean;
  billingAddressParameters?: GooglePayBillingAddressParameters;
  assuranceDetailsRequired?: boolean;
}

export interface GooglePayButtonConfig {
  buttonColor?: GooglePayButtonColor;
  buttonType?: GooglePayButtonType;
  buttonLocale?: string;
}

export interface GooglePayPaymentData {
  emailRequired?: boolean;
  shippingAddressRequired?: boolean;
  shippingOptionRequired?: boolean;
  shippingAddressParameters?: GooglePayShippingAddressParameters;
  shippingOptionParameters?: GooglePayShippingOptionParameters;
}

export interface GooglePayShippingOptionParameters {
  shippingOptions: GooglePayShippingOption[];
  defaultSelectedOptionId?: string;
}

export interface GooglePayShippingOption {
  id: string;
  label: string;
  description: string;
}

export enum GooglePayCardAuthMethod {
  PAN_ONLY = 'PAN_ONLY',
  CRYPTOGRAM_3DS = 'CRYPTOGRAM_3DS',
}

export interface GooglePayBillingAddressParameters {
  format?: string;
  phoneNumberRequired?: boolean;
}

export type GooglePayBillingAddressParametersFormat = 'MIN' | 'FULL';

export type GooglePayEnvironment = 'TEST' | 'PRODUCTION';

export type GooglePayButtonType = 'book' | 'buy' | 'checkout' | 'donate' | 'order' | 'pay' | 'plain' | 'subscribe';

export type GooglePayButtonColor = 'black' | 'white';

export type GooglePayTotalPriceStatus = 'FINAL' | 'ESTIMATED' | 'NOT_CURRENTLY_KNOWN';

export interface GooglePayTransactionInfo {
  currencyCode: string; // required for EEA only
  totalPriceStatus: string;
  totalPrice: string;
  transactionId: string;

  countryCode?: string;
  totalPriceLabel?: string; // required if displayItems is provided
  displayItems?: GooglePayDisplayItem[];
}

export type GooglePayCheckoutOption = 'DEFAULT' | 'COMPLETE_IMMEDIATE_PURCHASE';

export type GooglePayDisplayLineItemType = 'LINE_ITEM' | 'SUBTOTAL';

export type GooglePayDisplayLineItemStatus = 'FINAL' | 'PENDING';

export interface GooglePayDisplayItem {
  label: string;
  type: string;
  price: string;
  status?: string;
}

export interface GooglePayMerchantInfo {
  merchantName: string;
  merchantId: string;
}

export interface GooglePayShippingAddressParameters {
  allowedCountryCodes?: string[];
  phoneNumberRequired?: boolean;
}

export interface GooglePayShippingAddressParameters {
  shippingOptions: GooglePaySelectionOption;
  defaultSelectedOptionId?: string;
}

export interface GooglePaySelectionOption {
  id: string;
  label: string;
  description: string;
}
