import { useCallback } from 'react';
import { CardInfoObject, PreregistrationData, TokenizePaymentMethodResult } from '@mangopay/vault-sdk';
import { MISSING_PARAMS_ERROR } from '@mangopay/checkout-sdk-core';
import { DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { tokenizePaymentMethod } from './tokenize-payment-method';
import { useGlobalContext } from '../../../globalContext';
import { useSentryDebugger } from '../../../sentryLogger';

export const useTokenizePaymentMethod = () => {
  const { options, activePaymentMethod } = useGlobalContext();
  const { addBreadcrumb } = useSentryDebugger();
  const handleTokenizePaymentMethod = useCallback(
    async (
      preregistrationData: PreregistrationData,
      cardInfoObject: CardInfoObject
    ): Promise<TokenizePaymentMethodResult> => {
      if (!options?.clientId) {
        return MISSING_PARAMS_ERROR;
      }

      let tokenizePaymentMethodResult: TokenizePaymentMethodResult;
      try {
        addBreadcrumb(DebuggerLogType.TOKENIZATION_STARTED, { paymentMethod: activePaymentMethod });
        tokenizePaymentMethodResult = await tokenizePaymentMethod(
          options.clientId,
          preregistrationData,
          cardInfoObject,
          options.environment,
          options.tenantId
        );
        return tokenizePaymentMethodResult;
      } catch (error) {
        return error as TokenizePaymentMethodResult;
      }
    },
    [options?.clientId]
  );

  return {
    tokenizePaymentMethod: handleTokenizePaymentMethod,
  };
};
