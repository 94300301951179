import { CheckoutSdkFrameEventType } from '@mangopay/checkout-sdk-hosted-core';
import { AuthorizePostMessagePayload } from '@mangopay/checkout-sdk-core';
import { RedirectUrlParams } from './types';
import {
  CARD_VALIDATION_ID_KEY,
  PRE_AUTHORIZATION_ID_KEY,
  STATUS_KEY,
  TRANSACTION_ID_KEY,
  DEPOSIT_ID_KEY,
  REDIRECTED_FROM_AUTH_KEY,
} from './redirect-param-keys';
import { PaymentStatus } from '../common';

export const getRedirectUrlParams = (): RedirectUrlParams => {
  const urlParams = new URLSearchParams(window.location.search);
  const transactionId = urlParams.get(TRANSACTION_ID_KEY);
  const depositId = urlParams.get(DEPOSIT_ID_KEY);
  const preAuthorizationId = urlParams.get(PRE_AUTHORIZATION_ID_KEY);
  const cardValidationId = urlParams.get(CARD_VALIDATION_ID_KEY);
  const status = urlParams.get(STATUS_KEY) as PaymentStatus;

  return {
    transactionId,
    depositId,
    preAuthorizationId,
    cardValidationId,
    status,
  };
};

export const getRedirectPayload = (urlParams: RedirectUrlParams): AuthorizePostMessagePayload => {
  const { transactionId, depositId, preAuthorizationId, cardValidationId, status } = urlParams;
  const Id = transactionId || depositId || preAuthorizationId || cardValidationId || '';
  return {
    Id,
    Status: status,
    Key: REDIRECTED_FROM_AUTH_KEY,
    eventType: CheckoutSdkFrameEventType.CloseHostAuthPopupWindow,
  };
};
