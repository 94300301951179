import { PaymentMethod, PaymentMethodType } from '@mangopay/sdk-payment-methods';
import { CheckoutSdkOptions, SdkOptions } from '@mangopay/checkout-sdk-elements-core';

const baseSdkOptions: Array<SdkOptions> = [SdkOptions.ClientId, SdkOptions.Amount, SdkOptions.ProfilingMerchantId];

type PaymentMethodSdkOptions = Partial<Record<PaymentMethod, Array<SdkOptions>>>;

const paymentMethodSdkOptions: PaymentMethodSdkOptions = {
  [PaymentMethod.CARD]: baseSdkOptions,
  [PaymentMethod.APPLE_PAY]: baseSdkOptions,
  [PaymentMethod.GOOGLE_PAY]: baseSdkOptions,
  [PaymentMethod.PAYPAL]: baseSdkOptions,
};

export interface FindMissingOptionsResult {
  missingOptions: SdkOptions[];
  hasMissingOptions: boolean;
}

export const findMissingOptions = (options?: CheckoutSdkOptions): FindMissingOptionsResult => {
  const missingOptions = baseSdkOptions.filter((option: SdkOptions) => (options ? !options?.[option] : true));
  const hasMissingOptions = missingOptions.length > 0;

  return {
    missingOptions,
    hasMissingOptions,
  };
};

export const findMissingOptionsByPaymentMethodType = (
  paymentMethodType: PaymentMethodType,
  options?: CheckoutSdkOptions
): FindMissingOptionsResult => {
  if (!paymentMethodType) throw new Error('Missing payment method types');
  const requiredOptions = paymentMethodSdkOptions[paymentMethodType];
  if (!requiredOptions) throw new Error(`Required params missing for payment method ${paymentMethodType}`);
  const missingOptions = requiredOptions.filter((option: SdkOptions) => (options ? !options?.[option] : true));
  const hasMissingOptions = missingOptions.length > 0;

  return {
    missingOptions,
    hasMissingOptions,
  };
};
