import React, { FunctionComponent, useState, useMemo } from 'react';
import { CheckoutSdkOptions, CheckoutPaymentMethodType } from '@mangopay/checkout-sdk-elements-core';
import { PreloadingComponent } from '@mangopay/checkout-sdk-react-elements';
import { generateUuid } from '@mangopay/checkout-sdk-core';
import { CheckoutSdk } from './checkout-sdk';
import { GlobalContextProvider, GlobalContextValues } from './globalContext';
import HandleRedirectComponent from './redirect/HandleRedirectComponent';
import {
  CARD_VALIDATION_ID_KEY,
  DEPOSIT_ID_KEY,
  PRE_AUTHORIZATION_ID_KEY,
  PRELOADING_KEY,
  TRANSACTION_ID_KEY,
} from './redirect/redirect-param-keys';
import { CheckoutFormState } from './common/types';
import { isHppContext } from './utils/execution-context';
import { HostedPaymentPage } from './hosted-payment-page/HostedPaymentPage';

const profilingAttemptReference = generateUuid();

export const App: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessState, setShowSuccessState] = useState<boolean>(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | undefined>();
  const [options, setOptions] = useState<CheckoutSdkOptions | undefined>();
  const [checkoutState, setCheckoutState] = useState<CheckoutFormState | undefined>(undefined);
  const [activePaymentMethod, setActivePaymentMethod] = useState<CheckoutPaymentMethodType>('card');
  const [isCardFormComplete, setIsCardFormComplete] = useState<boolean>(false);
  const [isCardFormValidationTriggered, setIsCardFormValidationTriggered] = useState<boolean>(false);
  // TODO: temporary hardcoded, will be added to options array
  const handlePaymentFlow = false;

  const urlParams = new URLSearchParams(window.location.search);
  const transactionId = urlParams.get(TRANSACTION_ID_KEY);
  const depositId = urlParams.get(DEPOSIT_ID_KEY);
  const preAuthorizationId = urlParams.get(PRE_AUTHORIZATION_ID_KEY);
  const cardValidationId = urlParams.get(CARD_VALIDATION_ID_KEY);
  const preloading = urlParams.get(PRELOADING_KEY);

  const contextValues: GlobalContextValues = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      options,
      setOptions,
      checkoutState,
      setCheckoutState,
      showSuccessState,
      setShowSuccessState,
      paymentErrorMessage,
      setPaymentErrorMessage,
      activePaymentMethod,
      setActivePaymentMethod,
      isCardFormComplete,
      setIsCardFormComplete,
      isCardFormValidationTriggered,
      setIsCardFormValidationTriggered,
      profilingAttemptReference,
      handlePaymentFlow,
    }),
    [
      isLoading,
      setIsLoading,
      options,
      setOptions,
      checkoutState,
      setCheckoutState,
      showSuccessState,
      setShowSuccessState,
      paymentErrorMessage,
      setPaymentErrorMessage,
      activePaymentMethod,
      setActivePaymentMethod,
      isCardFormComplete,
      setIsCardFormComplete,
      isCardFormValidationTriggered,
      setIsCardFormValidationTriggered,
      profilingAttemptReference,
      handlePaymentFlow,
    ]
  );
  const isHandleRedirect = !isHppContext && (transactionId || depositId || preAuthorizationId || cardValidationId);
  const AppComponent = isHppContext ? HostedPaymentPage : CheckoutSdk;
  return (
    <GlobalContextProvider value={contextValues}>
      {isHandleRedirect ? <HandleRedirectComponent /> : <AppComponent />}
      {preloading ? <PreloadingComponent /> : null}
    </GlobalContextProvider>
  );
};

App.propTypes = {};
App.defaultProps = {};

export default App;
