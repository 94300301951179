import { Validator } from './validator';

export const patternValidatorKey = 'pattern';

export const patternValidator = (pattern: RegExp): Validator<typeof patternValidatorKey> => {
  return (value: string) => {
    const isValid = pattern.test(value);
    return {
      [patternValidatorKey]: isValid,
    };
  };
};

export type PatternValidatorResult = ReturnType<ReturnType<typeof patternValidator>>;
